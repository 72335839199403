<style lang="less" scoped>
.full {
  padding: 0;
  margin: 0;
}
.allInfo {
  background-color: #e3e1e1;
  padding: 0px 10px;
  font-weight: bold;
}
.home-header {
  background: #2d507e;
  cursor: default;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: b;
  padding: 0 30px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.home-menu {
  min-width: 180px;
  background: #ffffff;
  height: calc(100vh - 4.5rem);
  margin: 3.5rem 0 1rem;
  overflow: auto;
  // min-width: 180px;
  /deep/ .el-menu {
    height: 100%;
    min-width: 180px;
  }
}
.home-menu::-webkit-scrollbar {
  display: none;
}

.home-main {
  height: calc(100vh - 4.5rem);
  margin: 3.5rem 0 1rem;
  background: #ffffff;
}
.el-table /deep/ .warning-row {
  background: #ffffcc;
}
.home-footer {
  background: #cccccc;
  text-align: center;
  font-size: 12px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 111111;
}
.form-title {
  font-size: 16px;
  margin-bottom: 15px;
}
.crt-content-dialog {
  z-index: 999;
  position: fixed;
}
.report-content {
  margin-top: 3rem;
  padding: 1rem;
  .content-search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .search-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .operation-table {
    .operation-item-table {
      border: none !important;
    }
    tr {
      td {
        div {
          padding: 0;
        }
      }
    }
    .cell {
      padding: 0 !important;
    }
  }
}
/deep/.el-table .cell {
  padding: 0;
}
</style>

<template>
  <div class="report">
    <el-container class="home-content">
      <el-header class="home-header" height="3.5rem">
        <div class="header-patient-info">统计报表</div>
        <div class="header-status">
          <div class="header-time">
            {{ local_time | formateTime }}
          </div>
        </div>
      </el-header>
    </el-container>
    <div class="report-content">
      <div class="content-tabs">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="手术工作量明细" name="first">
            <div class="content-search">
              <div>
                按日期查询：
                <el-date-picker
                  v-model="dialogQueryDate1"
                  type="daterange"
                  align="center"
                  size="mini"
                  style="margin-right: 10px"
                  unlink-panels
                  @change="handleNextDate"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </div>
              <div class="search-item">
                科室：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="deptName1"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in deptUserList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                主刀医生：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="doctor1"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in doctUserList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                手术室：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="room1"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in labList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                手术名称：
                <div style="margin-right: 10px">
                  <!-- <el-input class="input-form-item-content"
                            placeholder="请输入"
                            type="string"
                            clearable
                            v-model="operation1"
                            @change="fetchData1"
                            size="mini">
                  </el-input> -->
                  <el-select
                    v-model="operation1"
                    :clearable="true"
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="remoteMethod"
                    placeholder="请选择"
                    :loading="surgeryNameLoading"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in pubOperationList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-refresh-left"
                class="commonBtn"
                v-debounce="clearTable"
              >
                清空
              </el-button>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-search"
                class="commonBtn"
                v-debounce="fetchData1"
              >
                查询
              </el-button>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-upload2"
                class="commonBtn"
                @click="exportExcel('one')"
              >
                导出
              </el-button>
            </div>
            <el-table
              v-loading="loading"
              :header-cell-style="{ padding: 0 }"
              :data="operationDetailsTable"
              :row-class-name="tableRowClassName"
              border
              :height="tableHeight"
            >
              <el-table-column
                header-align="center"
                label="开始时间"
                align="center"
                prop="startDate"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="手术日期"
                align="center"
                prop="studyDate"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="患者姓名"
                align="center"
                prop="patientName"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="手术名称"
                align="center"
                prop="examineName"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="手术室"
                align="center"
                prop="lab"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="科室"
                align="center"
                prop="sickArea"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="主刀医生"
                align="center"
                prop="examineDoctor"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="一助"
                align="center"
                prop="assistantDoctor1"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="二助"
                align="center"
                prop="assistantDoctor2"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="护士"
                align="center"
                prop="scrubNurse"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="巡回"
                align="center"
                prop="tour"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="麻醉医生"
                align="center"
                prop="anesthesiaDoctor"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="技师"
                align="center"
                prop="tech"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="手术类型"
                align="center"
                prop="examineType"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="手术时长(min)"
                align="center"
                prop="studyDuration"
                show-overflow-tooltip
              ></el-table-column>
              <!-- <el-table-column
                header-align="center"
                label="血压BP"
                width="80"
                align="center"
                prop="bp"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.bp_sys && scope.row.bp_dia">
                    {{ scope.row.bp_sys }}/{{ scope.row.bp_dia }}
                  </div>
                  <div v-else>{{ scope.row.bp }}</div>
                </template>
              </el-table-column> -->
            </el-table>
            <div class="allInfo">
              总计: {{ operationDetailsTable[operationDetailsTable.length - 1]?.title }}
            </div>
          </el-tab-pane>
          <el-tab-pane label="科室手术统计" name="second">
            <div class="content-search">
              <div>
                按日期查询：
                <el-date-picker
                  v-model="dialogQueryDate2"
                  type="daterange"
                  align="center"
                  size="mini"
                  style="margin-right: 10px"
                  unlink-panels
                  @change="handleNextDate"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </div>
              <div class="search-item">
                科室：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="deptName1"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in deptUserList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                主刀医生：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="doctor1"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in doctUserList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                手术室：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="room1"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in labList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                手术名称：
                <div style="margin-right: 10px">
                  <!-- <el-input class="input-form-item-content"
                            placeholder="请输入"
                            type="string"
                            clearable
                            v-model="operation1"
                            @change="fetchData1"
                            size="mini">
                  </el-input> -->
                  <el-select
                    v-model="operation1"
                    :clearable="true"
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="remoteMethod"
                    placeholder="请选择"
                    :loading="surgeryNameLoading"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in pubOperationList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-refresh-left"
                class="commonBtn"
                v-debounce="clearTable"
              >
                清空
              </el-button>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-search"
                class="commonBtn"
                v-debounce="fetchData1"
              >
                查询
              </el-button>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-upload2"
                class="commonBtn"
                @click="exportExcel('two')"
              >
                导出
              </el-button>
            </div>
            <el-table
              stripe
              v-loading="loading"
              :header-cell-style="{ padding: 0 }"
              :data="departmentOperationTable"
              border
              :height="tableHeight"
              :span-method="handleSpanMethod1"
            >
              <el-table-column prop="sickArea" label="科室" align="center" />
              <el-table-column prop="allcount" label="总台数" align="center" />
              <el-table-column prop="etcount" label="急诊" align="center" />
              <el-table-column
                prop="examineName"
                label="手术名称"
                align="center"
                show-overflow-tooltip
              />
              <el-table-column prop="examineData.allcount" label="台数" align="center" />
              <el-table-column prop="examineData.etcount" label="急诊" align="center" />
              <el-table-column prop="examineData.duration" label="总时长" align="center" />
              <el-table-column prop="examineData" label="主刀医生" align="center">
                <div slot-scope="scope">
                  <div
                    v-for="(item, index) in scope.row.examineData?.doctorList"
                    :key="index"
                    style="width: 100%"
                  >
                    <p
                      style="padding: 5px 0; line-height: 25px; height: 30px"
                      :style="
                        index !== scope.row.examineData.doctorList?.length - 1 &&
                        'border-bottom: 1px solid #EBEEF5;'
                      "
                    >
                      {{ item.EXAMINEDOCTOR }}
                    </p>
                  </div>
                </div>
              </el-table-column>
              <el-table-column prop="MAINCOUNT" label="台数" align="center">
                <div slot-scope="scope">
                  <div
                    v-for="(item, index) in scope.row.examineData?.doctorList"
                    :key="index"
                    style="width: 100%"
                  >
                    <p
                      style="padding: 5px 0; line-height: 25px; height: 30px"
                      :style="
                        index !== scope.row.examineData.doctorList?.length - 1 &&
                        'border-bottom: 1px solid #EBEEF5;'
                      "
                    >
                      {{ item.MAINCOUNT }}
                    </p>
                  </div>
                </div>
              </el-table-column>
              <el-table-column prop="examineDoctor" label="急诊" align="center">
                <div slot-scope="scope">
                  <div
                    v-for="(item, index) in scope.row.examineData?.doctorList"
                    :key="index"
                    style="width: 100%"
                  >
                    <p
                      style="padding: 5px 0; line-height: 25px; height: 30px"
                      :style="
                        index !== scope.row.examineData.doctorList?.length - 1 &&
                        'border-bottom: 1px solid #EBEEF5;'
                      "
                    >
                      {{ item.ETCOUNT }}
                    </p>
                  </div>
                </div>
              </el-table-column>
              <el-table-column prop="ETCOUNT" label="总时长" align="center">
                <div slot-scope="scope">
                  <div
                    v-for="(item, index) in scope.row.examineData?.doctorList"
                    :key="index"
                    style="width: 100%"
                  >
                    <p
                      style="padding: 5px 0; line-height: 25px; height: 30px"
                      :style="
                        index !== scope.row.examineData.doctorList?.length - 1 &&
                        'border-bottom: 1px solid #EBEEF5;'
                      "
                    >
                      {{ item.DURATION }}
                    </p>
                  </div>
                </div>
              </el-table-column>
            </el-table>
            <div class="allInfo">
              总计: {{ departmentOperationTable[departmentOperationTable.length - 1]?.title }}
            </div>
            <!-- <el-table
              :header-cell-style="{
                // padding: 0,
                background: 'rgb(222,228,233)',
                border: 'none',
              }"
              :data="departmentOperationTable"
              style="width: 100%"
              height="450"
              border
              row-key="sickArea"
              class="operation-table"
              :default-expand-all="true"
            >
              <el-table-column
                type="expand"
                header-align="center"
                align="center"
              >
                <template slot-scope="props">
                  <el-table
                    :header-cell-style="{
                      // padding: 0,
                      background: 'rgb(222,228,233)',
                      border: 'none',
                    }"
                    border
                    row-key="examineName"
                    :data="props.row.examineList"
                    style="width: 100%,border:none"
                    class="operation-item-table"
                    :default-expand-all="true"
                  >
                    <el-table-column
                      type="expand"
                      header-align="center"
                      align="center"
                    >
                      <template slot-scope="item">
                        <el-table
                          :header-cell-style="{
                            // padding: 0,
                            background: 'rgb(222,228,233)',
                            border: 'none',
                          }"
                          border
                          row-key="EXAMINEDOCTOR"
                          :data="item.row.examineData.doctorList"
                          style="width: 100%,border:none"
                          class="operation-item-table"
                          :default-expand-all="true"
                        >
                          <el-table-column
                            header-align="center"
                            label="科室"
                            align="center"
                            prop="SICKAREA"
                            show-overflow-tooltip
                          >
                          </el-table-column>
                          <el-table-column
                            header-align="center"
                            label="医生"
                            align="center"
                            prop="EXAMINEDOCTOR"
                            show-overflow-tooltip
                          >
                          </el-table-column>
                          <el-table-column
                            header-align="center"
                            label="总时长"
                            align="center"
                            prop="ETCOUNT"
                            show-overflow-tooltip
                          >
                          </el-table-column>
                          <el-table-column
                            header-align="center"
                            label="台数"
                            align="center"
                            prop="MAINCOUNT"
                            show-overflow-tooltip
                          >
                          </el-table-column>
                        </el-table>
                      </template>
                    </el-table-column>
                    <el-table-column
                      header-align="center"
                      label="手术名称"
                      align="center"
                      prop="examineName"
                      show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                      header-align="center"
                      label="总时长"
                      align="center"
                      show-overflow-tooltip
                    >
                      <template slot-scope="item">
                        <span>{{ item.row.examineData.duration }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column
                header-align="center"
                label="科室"
                align="center"
                prop="sickArea"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                header-align="center"
                label="总台数"
                align="center"
                prop="allcount"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                header-align="center"
                label="急诊"
                align="center"
                prop="etcount"
                show-overflow-tooltip
              >
              </el-table-column>
            </el-table> -->
          </el-tab-pane>
          <el-tab-pane label="医生手术统计" name="third">
            <div class="content-search">
              <div>
                按日期查询：
                <el-date-picker
                  v-model="dialogQueryDate3"
                  type="daterange"
                  align="center"
                  size="mini"
                  style="margin-right: 10px"
                  unlink-panels
                  @change="handleNextDate"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </div>
              <div class="search-item">
                科室：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="deptName1"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in deptUserList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                主刀医生：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="doctor1"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in doctUserList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                手术室：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="room1"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in labList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                手术名称：
                <div style="margin-right: 10px">
                  <!-- <el-input class="input-form-item-content"
                            placeholder="请输入"
                            type="string"
                            clearable
                            v-model="operation1"
                            @change="fetchData1"
                            size="mini">
                  </el-input> -->
                  <el-select
                    v-model="operation1"
                    :clearable="true"
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="remoteMethod"
                    placeholder="请选择"
                    :loading="surgeryNameLoading"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in pubOperationList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-refresh-left"
                class="commonBtn"
                v-debounce="clearTable"
              >
                清空
              </el-button>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-search"
                class="commonBtn"
                v-debounce="fetchData1"
              >
                查询
              </el-button>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-upload2"
                class="commonBtn"
                @click="exportExcel('three')"
              >
                导出
              </el-button>
            </div>
            <el-table
              stripe
              v-loading="loading"
              :header-cell-style="{ padding: 0 }"
              :data="doctorOperationTable"
              border
              :height="tableHeight"
              :span-method="handleSpanMethod2"
            >
              <el-table-column
                header-align="center"
                label="医生"
                align="center"
                prop="doctor"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="科室"
                align="center"
                prop="deptName"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="总台数"
                width="120"
                align="center"
                prop="mainCount"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="急诊"
                width="60"
                align="center"
                prop="etCount"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="手术名称"
                align="center"
                prop="EXAMINENAME"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="台数"
                width="80"
                align="center"
                prop="MAINCOUNT"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="急诊"
                width="80"
                align="center"
                prop="ETCOUNT"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="平均手术时间"
                width="120"
                align="center"
                prop="duration"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="手术时间"
                align="center"
                prop="TIMETEXT"
                show-overflow-tooltip
              ></el-table-column>
            </el-table>
            <div class="allInfo">
              总计: {{ doctorOperationTable[doctorOperationTable.length - 1]?.title }}
            </div>
          </el-tab-pane>
          <el-tab-pane label="手术室统计" name="fourth">
            <div class="content-search">
              <div>
                按日期查询：
                <el-date-picker
                  v-model="dialogQueryDate4"
                  type="daterange"
                  align="center"
                  size="mini"
                  style="margin-right: 10px"
                  unlink-panels
                  @change="handleNextDate"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </div>
              <div class="search-item">
                科室：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="deptName1"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in deptUserList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                主刀医生：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="doctor1"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in doctUserList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                手术室：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="room1"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in labList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                手术名称：
                <div style="margin-right: 10px">
                  <!-- <el-input class="input-form-item-content"
                            placeholder="请输入"
                            type="string"
                            clearable
                            v-model="operation1"
                            @change="fetchData1"
                            size="mini">
                  </el-input> -->
                  <el-select
                    v-model="operation1"
                    :clearable="true"
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="remoteMethod"
                    placeholder="请选择"
                    :loading="surgeryNameLoading"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in pubOperationList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-refresh-left"
                class="commonBtn"
                v-debounce="clearTable"
              >
                清空
              </el-button>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-search"
                class="commonBtn"
                v-debounce="fetchData1"
              >
                查询
              </el-button>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-upload2"
                class="commonBtn"
                @click="exportExcel('four')"
              >
                导出
              </el-button>
            </div>
            <el-table
              stripe
              v-loading="loading"
              :header-cell-style="{ padding: 0 }"
              :data="labCountTable"
              border
              :height="tableHeight"
              :span-method="handleSpanMethod3"
            >
              <el-table-column prop="lab" label="手术室" align="center" />
              <el-table-column prop="opCount" label="总台数" align="center" />
              <el-table-column prop="etCount" label="急诊" align="center" />
              <el-table-column prop="doctor" label="主刀医生" align="center" />
              <el-table-column
                prop="examineName"
                label="手术名称"
                align="center"
                show-overflow-tooltip
              />
              <el-table-column prop="mainCount" label="台数" align="center" />
              <el-table-column prop="etCount" label="急诊" align="center" />
              <el-table-column prop="duration" label="平均手术时长" align="center" />
              <el-table-column prop="timeText" label="手术时间" align="center"></el-table-column>
            </el-table>
            <div class="allInfo">总计: {{ labCountTable[labCountTable.length - 1]?.title }}</div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
var parseDate = function (datetime) {
  return datetime < 10 ? '0' + datetime : datetime
}
// import CmDialogBorder from "../../../components/CmDialogBorder.vue";
// import CmSelect from '../../../components/CmSelect.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { getBeforeDate } from '../../../utils'

export default {
  name: 'ReportDialog',
  components: {
    // CmDialogBorder,
    // CmSelect,
  },
  data() {
    return {
      local_time: new Date(),
      tableHeight: window.innerHeight - 200, //表格动态高度
      screenHeight: window.innerHeight, //内容区域高度
      loading: false,
      isShow: false,
      zIndex: PopupManager.nextZIndex(),
      dialogQueryDate1: null,
      dialogQueryDate2: null,
      dialogQueryDate3: null,
      dialogQueryDate4: null,
      deptName1: null,
      doctor1: null,
      operation1: null,
      room1: null,
      changeValue: '',
      pubOperationList: [],
      queryGet: '',
      surgeryNameLoading: false,
      activeName: 'first',
      // 手术工作量明细
      operationDetailsTable: [],
      // 科室手术统计
      departmentOperationTable: [],
      // 医生手术统计明细
      doctorOperationTable: [],
      // 手术室统计
      labCountTable: [],
      // 导入日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              end.setTime(end.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      // 合并单元格
      column1Arr: [], // column1
      column1Index: 0, // column1索引
      column2Arr: [], // column2
      column2Index: 0, // column2索引
      column3Arr: [], // column3
      column3Index: 0, // column3索引
      column4Arr: [], // column4
      column4Index: 0, // column4
      column5Arr: [], // column5
      column5Index: 0, // column5索引
      column6Arr: [], // column6
      column6Index: 0, // column6索引
      labList: [],
      deptUserList: [],
      doctUserList: []
    }
  },
  filters: {
    formateTime: function (val) {
      var date = new Date(val)
      var year = date.getFullYear()
      var month = parseDate(date.getMonth() + 1)
      var day = parseDate(date.getDate())
      var hours = parseDate(date.getHours())
      var min = parseDate(date.getMinutes())
      var sec = parseDate(date.getSeconds())
      // var week = date.getDay();
      return year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec + ' '
    }
  },
  created() {
    this.timer = setInterval(() => {
      this.local_time = new Date()
    }, 1000)
  },
  mounted() {
    this.getData()
    this.getPubOperation()
    this.fetchData1()
    window.onresize = () => {
      return () => {
        // window.innerHeight:浏览器的可用高度
        window.screenHeight = window.innerHeight
        this.screenHeight = window.screenHeight
      }
    }
    this.getHomeLabList()
    this.getDeptUserList()
    this.getUserName()
    // this.fetchData2();
    // this.fetchData3();
  },
  computed: {},
  props: {
    data: {
      type: Array,
      default: () => []
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    data: function (val) {
      if (val) {
        this.dataList = val
      }
    },
    // 监听screenHeight从而改变table的高度
    screenHeight(val) {
      this.screenHeight = val
      this.tableHeight = this.screenHeight - 200
    }
  },
  methods: {
    remoteMethod(query) {
      if (query !== '') {
        this.surgeryNameLoading = true
        setTimeout(() => {
          this.surgeryNameLoading = false
          this.getPubOperation(query)
        }, 200)
      } else {
        this.pubOperationList = []
      }
    },
    getPubOperation(query) {
      if (query) {
        this.queryGet = query
      }
      this.$api
        .get(`/v1/webconsole/study/puboperationlist?keyword=${this.queryGet}`)
        .then((res) => {
          let arr = []
          if (res.data.data.length > 0) {
            res.data.data.map((item) => {
              arr.push({ label: item.dictName, value: item.dictName })
            })
            this.pubOperationList = arr
          }
        })
    },
    changeSearchSurgeryName(val) {
      if (val != undefined) {
        // 判断值和变量,如果之和变量相等,则不做操作,否则执行
        if (this.changeValue == val) {
          return
        } else {
          // 储存我所改变的值
          this.changeValue = val
          this.fetchData1()
        }
      }
    },
    // 初始化数据
    fetchData1() {
      this.loading = true
      let params = {
        deptName: this.deptName1 || null,
        examineDoctor: this.doctor1 || null,
        operationName: this.operation1 || null,
        lab: this.room1 || null
      }
      if (this.activeName === 'first') {
        // 手术工作量明细
        this.operationDetailsTable = []
        // 科室手术统计
        this.departmentOperationTable = []
        // 医生手术统计明细
        this.doctorOperationTable = []
        // 手术室统计
        this.labCountTable = []
        if (this.dialogQueryDate1) {
          this.$api
            .get(
              `/v1/webconsole/statistical/report/operationDetails?startTime=${
                this.dialogQueryDate1[0] || ''
              }&endTime=${this.dialogQueryDate1[1] || ''}`,
              params
            )
            .then((res) => {
              this.operationDetailsTable = res.data.data
              this.loading = false
            })
            .catch((this.loading = false))
        } else {
          this.loading = true
          this.$api
            .get(`/v1/webconsole/statistical/report/operationDetails`, params)
            .then((res) => {
              this.operationDetailsTable = res.data.data
              this.loading = false
            })
            .catch((this.loading = false))
        }
      } else if (this.activeName === 'second') {
        // 手术工作量明细
        this.operationDetailsTable = []
        // 科室手术统计
        this.departmentOperationTable = []
        // 医生手术统计明细
        this.doctorOperationTable = []
        // 手术室统计
        this.labCountTable = []
        if (this.dialogQueryDate2) {
          this.$api
            .get(
              `/v1/webconsole/statistical/report/departmentOperationCount?startTime=${
                this.dialogQueryDate2[0] || ''
              }&endTime=${this.dialogQueryDate2[1] || ''}`,
              params
            )
            .then((res) => {
              this.mergeInit()
              const newTableData = res.data.data
              // this.doctorOperationTable = res.data.data;
              newTableData.map((r, index) => {
                const parentId = index
                this.departmentOperationTable.push.apply(
                  this.departmentOperationTable,
                  this.handleData([r], parentId)
                )
              })
              this.mergeTable1(this.departmentOperationTable)
              this.loading = false
            })
            .catch((this.loading = false))
        } else {
          this.loading = true
          this.$api
            .get(`/v1/webconsole/statistical/report/departmentOperationCount`, params)
            .then((res) => {
              this.mergeInit()
              const newTableData = res.data.data
              // this.doctorOperationTable = res.data.data;
              newTableData.map((r, index) => {
                const parentId = index
                this.departmentOperationTable.push.apply(
                  this.departmentOperationTable,
                  this.handleData([r], parentId)
                )
              })
              this.mergeTable1(this.departmentOperationTable)
              this.loading = false
            })
            .catch((this.loading = false))
        }
      } else if (this.activeName === 'third') {
        // 手术工作量明细
        this.operationDetailsTable = []
        // 科室手术统计
        this.departmentOperationTable = []
        // 医生手术统计明细
        this.doctorOperationTable = []
        // 手术室统计
        this.labCountTable = []
        if (this.dialogQueryDate3) {
          this.$api
            .get(
              `/v1/webconsole/statistical/report/doctorOperationCount?startTime=${
                this.dialogQueryDate3[0] || ''
              }&endTime=${this.dialogQueryDate3[1] || ''}`,
              params
            )
            .then((res) => {
              this.mergeInit()
              const newTableData = res.data.data
              // this.doctorOperationTable = res.data.data;
              newTableData.map((r, index) => {
                const parentId = index
                this.doctorOperationTable.push.apply(
                  this.doctorOperationTable,
                  this.handleData([r], parentId)
                )
              })
              this.mergeTable(this.doctorOperationTable)
              this.loading = false
            })
            .catch((this.loading = false))
        } else {
          this.loading = true
          this.$api
            .get(`/v1/webconsole/statistical/report/doctorOperationCount`, params)
            .then((res) => {
              this.mergeInit()
              const newTableData = res.data.data
              // this.doctorOperationTable = res.data.data;
              newTableData.map((r, index) => {
                const parentId = index
                this.doctorOperationTable.push.apply(
                  this.doctorOperationTable,
                  this.handleData([r], parentId)
                )
              })
              this.mergeTable(this.doctorOperationTable)
              this.loading = false
            })
            .catch((this.loading = false))
        }
      } else if (this.activeName === 'fourth') {
        // 手术工作量明细
        this.operationDetailsTable = []
        // 科室手术统计
        this.departmentOperationTable = []
        // 医生手术统计明细
        this.doctorOperationTable = []
        // 手术室统计
        this.labCountTable = []
        if (this.dialogQueryDate4) {
          this.$api
            .get(
              `/v1/webconsole/statistical/report/labCountDetails?startTime=${
                this.dialogQueryDate4[0] || ''
              }&endTime=${this.dialogQueryDate4[1] || ''}`,
              params
            )
            .then((res) => {
              this.mergeInit()
              const newTableData = res.data.data
              // this.doctorOperationTable = res.data.data;
              /* newTableData?.forEach(item=>{
                item?.doctorList?.push(item?.opCount, item?.rowCount, item?.etCount, item?.lab)
              }) */
              newTableData.map((r, index) => {
                const parentId = index
                this.labCountTable.push.apply(this.labCountTable, this.handleData([r], parentId))
              })
              this.mergeTable2(this.labCountTable)
              this.loading = false
            })
            .catch((this.loading = false))
        } else {
          this.loading = true
          this.$api
            .get(`/v1/webconsole/statistical/report/labCountDetails`, params)
            .then((res) => {
              this.mergeInit()
              const newTableData = res.data.data
              // this.doctorOperationTable = res.data.data;
              newTableData.map((r, index) => {
                const parentId = index
                this.labCountTable.push.apply(this.labCountTable, this.handleData([r], parentId))
              })
              this.mergeTable1(this.labCountTable)
              this.loading = false
            })
            .catch((this.loading = false))
        }
      }
    },
    handleNextDate() {
      this.fetchData1()
    },
    clearTable() {
      this.dialogQueryDate1 = null
      this.dialogQueryDate2 = null
      this.dialogQueryDate3 = null
      this.dialogQueryDate4 = null
      this.deptName1 = null
      this.deptName1 = null
      this.doctor1 = null
      this.operation1 = null
      this.room1 = null
      this.fetchData1()
      this.getPubOperation()
    },
    getData() {
      let nextDateStart = new Date()
      let nextDateEnd = new Date()
      if (this.activeName === 'first') {
        this.dialogQueryDate1 = [
          getBeforeDate(0) + ' ' + '00:00:00',
          nextDateEnd.getFullYear() +
            '-' +
            (nextDateEnd.getMonth() + 1 < 10 ? '0' : '') +
            (nextDateEnd.getMonth() + 1) +
            '-' +
            (nextDateStart.getDate() + 1 < 10 ? '0' : '') +
            nextDateStart.getDate() +
            ' ' +
            '00:00:00'
        ]
      } else if (this.activeName === 'second') {
        this.dialogQueryDate2 = [
          getBeforeDate(0) + ' ' + '00:00:00',
          nextDateEnd.getFullYear() +
            '-' +
            (nextDateEnd.getMonth() + 1 < 10 ? '0' : '') +
            (nextDateEnd.getMonth() + 1) +
            '-' +
            (nextDateStart.getDate() + 1 < 10 ? '0' : '') +
            nextDateStart.getDate() +
            ' ' +
            '00:00:00'
        ]
      } else if (this.activeName === 'third') {
        this.dialogQueryDate3 = [
          getBeforeDate(0) + ' ' + '00:00:00',
          nextDateEnd.getFullYear() +
            '-' +
            (nextDateEnd.getMonth() + 1 < 10 ? '0' : '') +
            (nextDateEnd.getMonth() + 1) +
            '-' +
            (nextDateStart.getDate() + 1 < 10 ? '0' : '') +
            nextDateStart.getDate() +
            ' ' +
            '00:00:00'
        ]
      } else if (this.activeName === 'fourth') {
        this.dialogQueryDate4 = [
          getBeforeDate(0),
          nextDateEnd.getFullYear() +
            '-' +
            (nextDateEnd.getMonth() + 1 < 10 ? '0' : '') +
            (nextDateEnd.getMonth() + 1) +
            '-' +
            (nextDateStart.getDate() + 1 < 10 ? '0' : '') +
            nextDateStart.getDate()
        ]
      }
    },
    Show() {
      this.isShow = true
    },
    // table 表格数据初始化处理，将树结构数据转为一维数组
    handleData(data, parentId) {
      data.map((res, index) => {
        var obj = {
          id: parentId,
          ...data[0]
        }
        for (const key in res) {
          const isarr = Object.values(res).find((age) => {
            return Array.isArray(age)
          })
          if (isarr) {
            if (Array.isArray(res[key])) {
              for (let i = 0; i < res[key].length; i++) {
                Object.assign(obj, res[key][i])
                data.push(obj)
                res[key].splice(i, 1)
                if (res[key].length === 0) {
                  data.splice(index, 1)
                }
                this.handleData(data, parentId)
              }
            } else {
              Object.assign(obj, { [key]: res[key] })
            }
          }
        }
      })
      return data
    },
    // 初始化合并行数组
    mergeInit() {
      this.column1Arr = [] // column1
      this.column1Index = 0 // column1索引
      this.column2Arr = [] // column2
      this.column2Index = 0 // column2索引
      this.column3Arr = [] // column3
      this.column3Index = 0 // column3索引
      this.column4Arr = [] // column4
      this.column4Index = 0 // column4索引
      this.column5Arr = [] // column5
      this.column5Index = 0 // column5索引
      this.column6Arr = [] // column6
      this.column6Index = 0 // column6索引
    },
    // 合并表格
    mergeTable(data) {
      this.mergeInit()
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if (i === 0) {
            // 第一行必须存在，以第一行为基准
            this.column1Arr.push(1) // column1
            this.column1Index = 0

            this.column2Arr.push(1) // column2
            this.column2Index = 0

            this.column3Arr.push(1) // column3
            this.column3Index = 0

            this.column4Arr.push(1) // column4
            this.column4Index = 0

            this.column5Arr.push(1) // column5
            this.column5Index = 0

            this.column6Arr.push(1) // column6
            this.column6Index = 0
          } else {
            // 判断当前元素与上一元素是否相同
            // column1
            if (data[i].column1 === data[i - 1].column1 && data[i].id === data[i - 1].id) {
              this.column1Arr[this.column1Index] += 1
              this.column1Arr.push(0)
            } else {
              this.column1Arr.push(1)
              this.column1Index = i
            }

            // column2
            if (data[i].column2 === data[i - 1].column2 && data[i].id === data[i - 1].id) {
              this.column2Arr[this.column2Index] += 1
              this.column2Arr.push(0)
            } else {
              this.column2Arr.push(1)
              this.column2Index = i
            }

            // column3
            if (data[i].column3 === data[i - 1].column3 && data[i].id === data[i - 1].id) {
              this.column3Arr[this.column3Index] += 1
              this.column3Arr.push(0)
            } else {
              this.column3Arr.push(1)
              this.column3Index = i
            }

            // column4
            if (data[i].column4 === data[i - 1].column4 && data[i].id === data[i - 1].id) {
              this.column4Arr[this.column4Index] += 0
              this.column4Arr.push(1)
            } else {
              this.column4Arr.push(1)
              this.column4Index = i
            }

            // column5
            if (
              data[i].column5 === data[i - 1].column5 &&
              data[i].column4 === data[i - 1].column4 &&
              data[i].id === data[i - 1].id
            ) {
              this.column5Arr[this.column5Index] += 0
              this.column5Arr.push(1)
            } else {
              this.column5Arr.push(1)
              this.column5Index = i
            }

            // column6
            if (
              data[i].column6 === data[i - 1].column6 &&
              data[i].column4 === data[i - 1].column4 &&
              data[i].id === data[i - 1].id
            ) {
              this.column6Arr[this.column6Index] += 0
              this.column6Arr.push(1)
            } else {
              this.column6Arr.push(1)
              this.column6Index = i
            }
          }
        }
      }
    },
    // 合并表格
    mergeTable1(data) {
      this.mergeInit()
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if (i === 0) {
            // 第一行必须存在，以第一行为基准
            this.column1Arr.push(1) // column1
            this.column1Index = 0

            this.column2Arr.push(1) // column2
            this.column2Index = 0

            this.column3Arr.push(1) // column3
            this.column3Index = 0

            this.column4Arr.push(1) // column4
            this.column4Index = 0

            this.column5Arr.push(1) // column5
            this.column5Index = 0

            this.column6Arr.push(1) // column6
            this.column6Index = 0
          } else {
            // 判断当前元素与上一元素是否相同
            // column1
            if (data[i].column1 === data[i - 1].column1 && data[i].id === data[i - 1].id) {
              this.column1Arr[this.column1Index] += 1
              this.column1Arr.push(0)
            } else {
              this.column1Arr.push(1)
              this.column1Index = i
            }

            // column2
            if (data[i].column2 === data[i - 1].column2 && data[i].id === data[i - 1].id) {
              this.column2Arr[this.column2Index] += 1
              this.column2Arr.push(0)
            } else {
              this.column2Arr.push(1)
              this.column2Index = i
            }

            // column3
            if (data[i].column3 === data[i - 1].column3 && data[i].id === data[i - 1].id) {
              this.column3Arr[this.column3Index] += 0
              this.column3Arr.push(1)
            } else {
              this.column3Arr.push(1)
              this.column3Index = i
            }

            // column4
            if (data[i].column4 === data[i - 1].column4 && data[i].id === data[i - 1].id) {
              this.column4Arr[this.column4Index] += 0
              this.column4Arr.push(1)
            } else {
              this.column4Arr.push(1)
              this.column4Index = i
            }

            // column5
            if (
              data[i].column5 === data[i - 1].column5 &&
              data[i].column4 === data[i - 1].column4 &&
              data[i].id === data[i - 1].id
            ) {
              this.column5Arr[this.column5Index] += 0
              this.column5Arr.push(1)
            } else {
              this.column5Arr.push(1)
              this.column5Index = i
            }

            // column6
            if (
              data[i].column6 === data[i - 1].column6 &&
              data[i].column4 === data[i - 1].column4 &&
              data[i].id === data[i - 1].id
            ) {
              this.column6Arr[this.column6Index] += 0
              this.column6Arr.push(1)
            } else {
              this.column6Arr.push(1)
              this.column6Index = i
            }
          }
        }
      }
    },
    // 合并表格
    mergeTable2(data) {
      this.mergeInit()
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if (i === 0) {
            // 第一行必须存在，以第一行为基准
            this.column1Arr.push(1) // column1
            this.column1Index = 0

            this.column2Arr.push(1) // column2
            this.column2Index = 0

            this.column3Arr.push(1) // column3
            this.column3Index = 0

            this.column4Arr.push(1) // column4
            this.column4Index = 0

            this.column5Arr.push(1) // column5
            this.column5Index = 0

            this.column6Arr.push(1) // column6
            this.column6Index = 0
          } else {
            // 判断当前元素与上一元素是否相同
            // column1
            if (data[i].column1 === data[i - 1].column1 && data[i].id === data[i - 1].id) {
              this.column1Arr[this.column1Index] += 1
              this.column1Arr.push(0)
            } else {
              this.column1Arr.push(1)
              this.column1Index = i
            }

            // column2
            if (data[i].column2 === data[i - 1].column2 && data[i].id === data[i - 1].id) {
              this.column2Arr[this.column2Index] += 1
              this.column2Arr.push(0)
            } else {
              this.column2Arr.push(1)
              this.column2Index = i
            }

            // column3
            if (data[i].column3 === data[i - 1].column3 && data[i].id === data[i - 1].id) {
              this.column3Arr[this.column3Index] += 0
              this.column3Arr.push(1)
            } else {
              this.column3Arr.push(1)
              this.column3Index = i
            }

            // column4
            if (data[i].column4 === data[i - 1].column4 && data[i].id === data[i - 1].id) {
              this.column4Arr[this.column4Index] += 0
              this.column4Arr.push(1)
            } else {
              this.column4Arr.push(1)
              this.column4Index = i
            }

            // column5
            if (
              data[i].column5 === data[i - 1].column5 &&
              data[i].column4 === data[i - 1].column4 &&
              data[i].id === data[i - 1].id
            ) {
              this.column5Arr[this.column5Index] += 0
              this.column5Arr.push(1)
            } else {
              this.column5Arr.push(1)
              this.column5Index = i
            }

            // column6
            if (
              data[i].column6 === data[i - 1].column6 &&
              data[i].column4 === data[i - 1].column4 &&
              data[i].id === data[i - 1].id
            ) {
              this.column6Arr[this.column6Index] += 0
              this.column6Arr.push(1)
            } else {
              this.column6Arr.push(1)
              this.column6Index = i
            }
          }
        }
      }
    },
    handleSpanMethod1({ rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        // 第一列 column1
        const _row_1 = this.column1Arr[rowIndex]
        const _col_1 = _row_1 > 0 ? 1 : 0
        return {
          rowspan: _row_1,
          colspan: _col_1
        }
      } else if (columnIndex === 2) {
        // 第二列 column2
        const _row_2 = this.column2Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 3) {
        // 第三列 column3
        const _row_2 = this.column3Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 4) {
        // 第四列 column4
        const _row_2 = this.column4Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 5) {
        // 第五列 column5
        const _row_2 = this.column5Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 6) {
        // 第六列 column6
        const _row_2 = this.column6Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      }
    },
    handleSpanMethod2({ rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        // 第一列 column1
        const _row_1 = this.column1Arr[rowIndex]
        const _col_1 = _row_1 > 0 ? 1 : 0
        return {
          rowspan: _row_1,
          colspan: _col_1
        }
      } else if (columnIndex === 2) {
        // 第二列 column2
        const _row_2 = this.column2Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 3) {
        // 第三列 column3
        const _row_2 = this.column3Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 4) {
        // 第四列 column4
        const _row_2 = this.column4Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 5) {
        // 第五列 column5
        const _row_2 = this.column5Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 6) {
        // 第六列 column6
        const _row_2 = this.column6Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      }
    },
    handleSpanMethod3({ rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        // 第一列 column1
        const _row_1 = this.column1Arr[rowIndex]
        const _col_1 = _row_1 > 0 ? 1 : 0
        return {
          rowspan: _row_1,
          colspan: _col_1
        }
      } else if (columnIndex === 2) {
        // 第二列 column2
        const _row_2 = this.column2Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 3) {
        // 第三列 column3
        const _row_2 = this.column3Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 4) {
        // 第四列 column4
        const _row_2 = this.column4Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 5) {
        // 第五列 column5
        const _row_2 = this.column5Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 6) {
        // 第六列 column6
        const _row_2 = this.column6Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      }
    },
    // 选择tabs
    handleClick(tab) {
      this.getData()
      this.loading = true
      ;(this.deptName1 = null), (this.doctor1 = null), (this.operation1 = null), (this.room1 = null)
      if (tab.index === '0' || tab === '0') {
        this.dialogQueryDate2 = []
        this.dialogQueryDate3 = []
        // 手术工作量明细
        this.operationDetailsTable = []
        // 科室手术统计
        this.departmentOperationTable = []
        // 医生手术统计明细
        this.doctorOperationTable = []
        this.$api
          .get(
            `/v1/webconsole/statistical/report/operationDetails?startTime=${
              this.dialogQueryDate1[0] || []
            }&endTime=${this.dialogQueryDate1[1] || []}`
          )
          .then((res) => {
            this.operationDetailsTable = res.data.data
            this.loading = false
          })
      } else if (tab.index === '1' || tab === '1') {
        this.loading = true
        this.mergeInit()
        this.dialogQueryDate1 = []
        this.dialogQueryDate3 = []
        // 手术工作量明细
        this.operationDetailsTable = []
        // 科室手术统计
        this.departmentOperationTable = []
        // 医生手术统计明细
        this.doctorOperationTable = []
        this.loading = true
        this.mergeInit()
        this.$api
          .get(
            `/v1/webconsole/statistical/report/departmentOperationCount?startTime=${
              this.dialogQueryDate2[0] || []
            }&endTime=${this.dialogQueryDate2[1] || []}`
          )
          .then((res) => {
            if (res.data.data.length > 0 && res.data.status === 200) {
              this.mergeInit()
              const newTableData = res.data.data
              // this.doctorOperationTable = res.data.data;
              newTableData.map((r, index) => {
                const parentId = index
                this.departmentOperationTable.push.apply(
                  this.departmentOperationTable,
                  this.handleData([r], parentId)
                )
              })
              this.mergeTable1(this.departmentOperationTable)
              this.loading = false
            } else {
              this.mergeInit()
              this.departmentOperationTable = res.data.data
              this.loading = false
            }
          })
      } else if (tab.index === '2' || tab === '2') {
        this.loading = true
        this.mergeInit()
        this.dialogQueryDate1 = []
        this.dialogQueryDate2 = []
        // 手术工作量明细
        this.operationDetailsTable = []
        // 科室手术统计
        this.departmentOperationTable = []
        // 医生手术统计明细
        this.doctorOperationTable = []
        this.loading = true
        this.mergeInit()
        this.$api
          .get(
            `/v1/webconsole/statistical/report/doctorOperationCount?startTime=${
              this.dialogQueryDate3[0] || []
            }&endTime=${this.dialogQueryDate3[1] || []}`
          )
          .then((res) => {
            if (res.data.data.length > 0 && res.data.status === 200) {
              this.mergeInit()
              const newTableData = res.data.data
              // this.doctorOperationTable = res.data.data;
              newTableData.map((r, index) => {
                const parentId = index
                this.doctorOperationTable.push.apply(
                  this.doctorOperationTable,
                  this.handleData([r], parentId)
                )
              })
              this.mergeTable(this.doctorOperationTable)
              this.loading = false
            } else {
              this.mergeInit()
              this.doctorOperationTable = res.data.data
              this.loading = false
            }
          })
      } else if (tab.index === '3' || tab === '3') {
        this.loading = true
        this.mergeInit()
        this.dialogQueryDate1 = []
        this.dialogQueryDate2 = []
        // 手术工作量明细
        this.operationDetailsTable = []
        // 科室手术统计
        this.departmentOperationTable = []
        // 医生手术统计明细
        this.doctorOperationTable = []
        // 手术室统计
        this.labCountTable = []
        this.loading = true
        this.mergeInit()
        this.$api
          .get(
            `/v1/webconsole/statistical/report/labCountDetails?startTime=${
              this.dialogQueryDate4[0] || []
            }&endTime=${this.dialogQueryDate4[1] || []}`
          )
          .then((res) => {
            if (res.data.data.length > 0 && res.data.status === 200) {
              this.mergeInit()
              const newTableData = res.data.data
              // this.doctorOperationTable = res.data.data;
              newTableData.map((r, index) => {
                const parentId = index
                this.labCountTable.push.apply(this.labCountTable, this.handleData([r], parentId))
              })
              this.mergeTable2(this.labCountTable)
              this.loading = false
            } else {
              this.mergeInit()
              this.labCountTable = res.data.data
              this.loading = false
            }
          })
      }
    },
    tableRowClassName({ row }) {
      if (!row.title) {
        if (row.studyDate) {
          return ''
        } else {
          return 'warning-row'
        }
      }
    },
    // 导出
    exportExcel(name) {
      if (name === 'one') {
        const params = `?startTime=${
          this.dialogQueryDate1?.length > 0 ? this.dialogQueryDate1[0] : []
        }&endTime=${this.dialogQueryDate1?.length > 0 ? this.dialogQueryDate1[1] : []}`
        this.axios
          .get(`/v1/webconsole/statistical/report/operationDetailsExcel${params}`, {
            responseType: 'blob'
          })
          .then((res) => {
            const { data, headers } = res
            const filename = headers['content-disposition'].split(';')[1].split('=')[1]
            const blob = new Blob([data], {
              type: headers['content-type']
            })
            let dom = document.createElement('a')
            let url = window.URL.createObjectURL(blob)
            dom.href = url
            dom.download = decodeURI(filename)
            dom.style.display = 'none'
            document.body.appendChild(dom)
            dom.click()
            dom.parentNode.removeChild(dom)
            window.URL.revokeObjectURL(url)
          })
      } else if (name === 'two') {
        const params = `?startTime=${
          this.dialogQueryDate2?.length > 0 ? this.dialogQueryDate2[0] : []
        }&endTime=${this.dialogQueryDate2?.length > 0 ? this.dialogQueryDate2[1] : []}`
        this.axios
          .get(`/v1/webconsole/statistical/report/departmentOperationExcel${params}`, {
            responseType: 'blob'
          })
          .then((res) => {
            const { data, headers } = res
            const filename = headers['content-disposition'].split(';')[1].split('=')[1]
            const blob = new Blob([data], {
              type: headers['content-type']
            })
            let dom = document.createElement('a')
            let url = window.URL.createObjectURL(blob)
            dom.href = url
            dom.download = decodeURI(filename)
            dom.style.display = 'none'
            document.body.appendChild(dom)
            dom.click()
            dom.parentNode.removeChild(dom)
            window.URL.revokeObjectURL(url)
          })
      } else if (name === 'three') {
        const params = `?startTime=${
          this.dialogQueryDate3?.length > 0 ? this.dialogQueryDate3[0] : []
        }&endTime=${this.dialogQueryDate3?.length > 0 ? this.dialogQueryDate3[1] : []}`
        this.axios
          .get(`/v1/webconsole/statistical/report/doctorOperationExcel${params}`, {
            responseType: 'blob'
          })
          .then((res) => {
            const { data, headers } = res
            const filename = headers['content-disposition'].split(';')[1].split('=')[1]
            const blob = new Blob([data], {
              type: headers['content-type']
            })
            let dom = document.createElement('a')
            let url = window.URL.createObjectURL(blob)
            dom.href = url
            dom.download = decodeURI(filename)
            dom.style.display = 'none'
            document.body.appendChild(dom)
            dom.click()
            dom.parentNode.removeChild(dom)
            window.URL.revokeObjectURL(url)
          })
      } else if (name === 'four') {
        const params = `?startTime=${
          this.dialogQueryDate4?.length > 0 ? this.dialogQueryDate4[0] : []
        }&endTime=${this.dialogQueryDate4?.length > 0 ? this.dialogQueryDate4[1] : []}`
        this.axios
          .get(`/v1/webconsole/statistical/report/labCountExcel${params}`, {
            responseType: 'blob'
          })
          .then((res) => {
            const { data, headers } = res
            const filename = headers['content-disposition'].split(';')[1].split('=')[1]
            const blob = new Blob([data], {
              type: headers['content-type']
            })
            let dom = document.createElement('a')
            let url = window.URL.createObjectURL(blob)
            dom.href = url
            dom.download = decodeURI(filename)
            dom.style.display = 'none'
            document.body.appendChild(dom)
            dom.click()
            dom.parentNode.removeChild(dom)
            window.URL.revokeObjectURL(url)
          })
      }
    },
    getHomeLabList() {
      this.$api.get(`/v1/webconsole/study/rooms`).then((res) => {
        console.log(res)
        let labArr = []
        if (res.data.data.length > 0) {
          res.data.data.map((item) => {
            labArr.push({ label: item.name, value: item.name })
          })
          this.labList = labArr
        }
      })
    },
    getDeptUserList() {
      this.$api.get(`/v1/webconsole/user/depts`).then((res) => {
        if (res.data && res.data.data.length > 0) {
          let labArr = []
          res.data.data.map((item) => {
            labArr.push({ label: item.name, value: item.name })
          })
          this.deptUserList = labArr
        }
      })
    },
    getUserName() {
      this.$api.get(`/v1/webconsole/study/doctors`).then((res) => {
        let labArr = []
        if (res.data.data.length > 0) {
          res.data.data.map((item) => {
            labArr.push({ label: item.userName, value: item.userName })
          })
          this.doctUserList = labArr
        }
      })
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>
